import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BlogPostSummary from "../components/blogPostSummary"
import styled from "styled-components"

const Title = styled.h3`
  text-align: center;
  margin-bottom: 0;
  font-size: 1.7rem;
`

export default ({ excludedBlogs }) => (
  <StaticQuery
    query={graphql`
      query getSuggestedPosts {
        suggestedPosts: allFile(
          filter: { relativeDirectory: { eq: "blog-posts" } }
          sort: {
            fields: [childMarkdownRemark___frontmatter___date]
            order: DESC
          }
        ) {
          nodes {
            name
            ...BlogPostSummary_post
          }
        }
      }
    `}
    render={({ suggestedPosts: { nodes: suggestedPosts } }) => (
      <>
        <Title>Read More</Title>
        <div className="grid-2-col">
          {suggestedPosts
            .sort(() => Math.random() - Math.random())
            .filter(post => !excludedBlogs.includes(post.name))
            .slice(0, 2)
            .map(post => (
              <BlogPostSummary key={post.name} post={post} />
            ))}
        </div>
      </>
    )}
  />
)
