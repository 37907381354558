import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import AuthorPanel from "../components/authorPanel"
import SuggestedArticles from "../components/suggestedArticles"
import { graphql } from "gatsby"

export default ({
  data: {
    post: {
      childMarkdownRemark: { frontmatter, html },
    },
    register: { childMarkdownRemark: register },
  },
  pageContext,
}) => {
  return (
    <Layout
      meta={{
        title: frontmatter.title,
        description: frontmatter.summary,
        image: frontmatter.metaImage,
      }}
    >
      <section>
        <div className="container-blog">
          <div className="row">
            <article>
              <div className="sub-page-heading">
                <span className="tag">{frontmatter.category}</span>
                <h1 className="blog-post-title">{frontmatter.title}</h1>
                <AuthorPanel
                  author={frontmatter.author}
                  date={frontmatter.date}
                />
              </div>
              <div className="post-img">
                <Img
                  className="img-responsive"
                  fluid={frontmatter.image.childImageSharp.fluid}
                  alt={frontmatter.title}
                />
              </div>
              <div
                className="blog-text"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </article>
          </div>
          <SuggestedArticles excludedBlogs={[pageContext.name]} />
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($name: String!) {
    post: file(relativeDirectory: { eq: "blog-posts" }, name: { eq: $name }) {
      childMarkdownRemark {
        html
        frontmatter {
          title
          summary
          date
          category
          author {
            ...AuthorPanel_author
          }
          metaImage: image {
            ...Layout_metaImage
          }
          image {
            childImageSharp {
              fluid(maxWidth: 910, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    register: file(relativePath: { eq: "pages/register.md" }) {
      childMarkdownRemark {
        html
        frontmatter {
          title
        }
      }
    }
  }
`
